<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card m-b-30">
        <div class="card-header text-center">
          <h5><i class="feather icon-user-plus mr-2"></i> Recetas Surtidas</h5>
        </div>
        <div class="card-body">
          <canvas id="chartjsTablePrecription" class="chartjs-chart" :height="$vuetify.breakpoint.smAndDown ? '320' : '250'"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { UtilFront } from "@/other/utils";

export default {
  data() {
    return {
      numeral: require("numeral"),
      dataList: [],
      tableInfo: [],
      chartJurisdiction: {},
      pointBorderColorGreen: UtilFront.getpointBorderColorGreen(),
      pointBorderColorBlue: UtilFront.getpointBorderColorBlue(),
      pointBorderColorOrange: UtilFront.getpointBorderColorOrange(),
      pointBorderColorGreenNew: UtilFront.getpointBorderColorGreenNew(),
      pointBackgroundColor: UtilFront.getpointBackgroundColor(),
    };
  },
  methods: {
    async refreshJurisdictionsBar() {
      this.dataList = this.dataListBackup;
      let anio2021 = [];
      let anio2022 = [];
      let anio2023 = [];
      let anio2024 = [];
      this.dataList.map((table) => {
        if (table.anio == "2021") {
          anio2021.push(table.quantityS);
        }
        if (table.anio == "2022") {
          anio2022.push(table.quantityS);
        }
        if (table.anio == "2023") {
          anio2023.push(table.quantityS);
        }
        if (table.anio == "2024") {
          anio2024.push(table.quantityS);
        }
      });
      this.chartJurisdiction.data.datasets = [
        {
          label: "2021",
          backgroundColor: ["#00a0ff"],
          borderColor: ["#00a0ff"],
          pointBorderColor: this.pointBorderColorBlue,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2021,
          fill: false,
        },
        {
          label: "2022",
          fill: false,
          backgroundColor: ["#37BC7F"],
          borderColor: ["#37BC7F"],
          pointBorderColor: this.pointBorderColorGreen,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2022,
        },
        {
          label: "2023",
          fill: false,
          backgroundColor: ["#f4730b"],
          borderColor: ["#f4730b"],
          pointBorderColor: this.pointBorderColorOrange,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2023,
        },
        {
          label: "2024",
          fill: false,
          backgroundColor: ["#EEC944"],
          borderColor: ["#EEC944"],
          pointBorderColor: this.pointBorderColorGreenNew,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2024,
        },
      ];
      this.chartJurisdiction.update();
    },
  },
  computed: {
    ...mapState("supply", {
      dataListBackup: "dataList",
    }),
  },
  mounted() {
    //Despliego el los porcentajes de de las jurisdiciones
    const ctxJurisdiction = document.getElementById("chartjsTablePrecription");
    window.char_js_table = this;
    this.chartJurisdiction = new Chart(ctxJurisdiction, this.jurisdictionBar);
  },
  async created() {
    let anio2021 = [];
    let anio2022 = [];
    let anio2023 = [];
    let anio2024 = [];
    this.dataList = this.dataListBackup;
    this.dataList.map((table) => {
      if (table.anio == "2021") {
        anio2021.push(table.quantityS);
      }
      if (table.anio == "2022") {
        anio2022.push(table.quantityS);
      }
      if (table.anio == "2023") {
        anio2023.push(table.quantityS);
      }
      if (table.anio == "2024") {
        anio2024.push(table.quantityS);
      }
    });
    this.tableInfo = {
      labels: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      datasets: [
        {
          label: "2021",
          backgroundColor: ["#00a0ff"],
          borderColor: ["#00a0ff"],
          pointBorderColor: this.pointBorderColorBlue,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2021,
          fill: false,
          pointHoverRadius: 6,
          pointHitRadius: 20,
        },
        {
          label: "2022",
          fill: false,
          backgroundColor: ["#37BC7F"],
          borderColor: ["#37BC7F"],
          pointBorderColor: this.pointBorderColorGreen,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2022,
          pointHoverRadius: 6,
          pointHitRadius: 20,
        },
        {
          label: "2023",
          fill: false,
          backgroundColor: ["#f4730b"],
          borderColor: ["#f4730b"],
          pointBorderColor: this.pointBorderColorOrange,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2023,
          pointHoverRadius: 6,
          pointHitRadius: 20,
        },
        {
          label: "2024",
          fill: false,
          backgroundColor: ["#EEC944"],
          borderColor: ["#EEC944"],
          pointBorderColor: this.pointBorderColorGreenNew,
          pointBackgroundColor: this.pointBackgroundColor,
          pointBorderWidth: 1,
          data: anio2024,
          pointHoverRadius: 6,
          pointHitRadius: 20,
        },
      ],
    };
    this.jurisdictionBar = {
      type: "line",
      data: this.tableInfo,
      options: {
        responsive: true,
        legend: {
          labels: {
            // This more specific font property overrides the global property
            fontFamily: "'Roboto-Regular'",
            fontSize: 15,
            fontStyle: "bold",
          },
        },
        tooltips: {
          titleFontFamily: "'Roboto-Regular'",
          titleFontSize: 16,
          bodyFontFamily: "'Roboto-Regular'",
          bodyFontSize: 14,
          bodySpacing: 8,
          xPadding: 15,
          yPadding: 15,
          cornerRadius: 16,
          displayColors: 200,
          footerFontFamily: "'Roboto-Regular'",
          mode: "index",
          intersect: false,
          position: "average",
          callbacks: {
            label: function(tooltipItems, data) {
              return (
                data.datasets[tooltipItems.datasetIndex].label +
                ": " +
                Intl.NumberFormat("es-MX").format(tooltipItems.yLabel)
              );
            },
            labelColor: function(tooltipItem, data) {
              if (tooltipItem.datasetIndex === 0) {
                return {
                  backgroundColor: "#00a0ff",
                };
              }
              if (tooltipItem.datasetIndex === 1) {
                return {
                  backgroundColor: "#37BC7F",
                };
              }
              if (tooltipItem.datasetIndex === 2) {
                return {
                  backgroundColor: "#f4730b",
                };
              }
              if (tooltipItem.datasetIndex === 3) {
                return {
                  backgroundColor: "#EEC944",
                };
              }
            },
          },
        },
        hover: {
          mode: "nearest",
          intersect: false,
        },
        elements: {
          line: {
            tension: 0.000001,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontFamily: "'Roboto-Regular'",
                maxRotation: 90,
                minRotation: 90,
                fontSize: 13,
              },
              display: true,
              scaleLabel: {
                display: true,
                fontFamily: "'Roboto-Regular'",
                labelString: "Meses",
                fontSize: 14,
                fontStyle: "bold",
              },
              gridLines: {
                color: "rgba(0,0,0,0.05)",
                lineWidth: 1,
                borderDash: [0],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: "'Roboto-Regular'",
                callback: function(value) {
                  return Intl.NumberFormat("es-MX").format(value);
                },
                beginAtZero: true,
                fontSize: 13,
                fontStyle: "bold",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "",
              },

              gridLines: {
                color: "rgba(0,0,0,0.05)",
                lineWidth: 1,
                borderDash: [0],
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    dataListBackup: {
      handler: function(val) {
        this.refreshJurisdictionsBar();
      },
    },
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
<style>
.chartjs-chart {
  font-family: "Roboto-Regular" !important;
}
</style>
