var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body pb-0"},[_c('h4',{staticClass:"text-right"},[_vm._v("CENTROS DE SALUD")]),_c('div',{staticClass:"pl-3"},[_c('h5',{staticClass:"mt-0 mb-1 font-16"},[_vm._v(" "+_vm._s(_vm.modelJurisdiction.maxPercentage.name)+" ")]),_vm._m(0),_c('v-progress-linear',{staticClass:"br mb-5",attrs:{"color":_vm.utilFront.colorPercentajeBar(
                _vm.modelJurisdiction.maxPercentage.percentageSupplier
              ),"height":"24","value":_vm.modelJurisdiction.maxPercentage.percentageSupplier,"rounded":"","dark":""}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.modelJurisdiction.maxPercentage.percentageSupplier))+"%")])]),_c('h5',{staticClass:"mt-0 mb-1 font-16"},[_vm._v(" "+_vm._s(_vm.modelJurisdiction.minPercentage.name)+" ")]),_vm._m(1),_c('v-progress-linear',{staticClass:"br mb-5",attrs:{"color":_vm.utilFront.colorPercentajeBar(
                _vm.modelJurisdiction.minPercentage.percentageSupplier
              ),"height":"24","value":_vm.modelJurisdiction.minPercentage.percentageSupplier,"rounded":"","dark":""}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.modelJurisdiction.minPercentage.percentageSupplier))+"%")])])],1)])])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body pb-0"},[_c('h4',{staticClass:"text-right"},[_vm._v("HOSPITALES")]),_c('div',{staticClass:"pl-3"},[_c('h5',{staticClass:"mt-0 mb-1 font-16"},[_vm._v(" "+_vm._s(_vm.modelJurisdiction.maxPercentageHospital.name)+" ")]),_vm._m(2),_c('v-progress-linear',{staticClass:"br mb-5",attrs:{"color":_vm.utilFront.colorPercentajeBar(
                _vm.modelJurisdiction.maxPercentageHospital.percentageSupplier
              ),"height":"24","value":_vm.modelJurisdiction.maxPercentageHospital.percentageSupplier,"rounded":"","dark":""}},[_c('strong',[_vm._v(_vm._s(Math.ceil( _vm.modelJurisdiction.maxPercentageHospital.percentageSupplier ))+"%")])]),_c('h5',{staticClass:"mt-0 mb-1 font-16"},[_vm._v(" "+_vm._s(_vm.modelJurisdiction.minPercentageHospital.name)+" ")]),_vm._m(3),_c('v-progress-linear',{staticClass:"br mb-5",attrs:{"color":_vm.utilFront.colorPercentajeBar(
                _vm.modelJurisdiction.minPercentageHospital.percentageSupplier
              ),"height":"24","value":_vm.modelJurisdiction.minPercentageHospital.percentageSupplier,"rounded":"","dark":""}},[_c('strong',[_vm._v(_vm._s(Math.ceil( _vm.modelJurisdiction.minPercentageHospital.percentageSupplier ))+"%")])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"feather icon-trending-up mr-2"}),_vm._v("Centro de Salud con Mayor Abasto ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"feather icon-trending-down mr-1"}),_vm._v("Centro de Salud con Menor Abasto ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"feather icon-trending-up mr-2"}),_vm._v("Hospital con Mayor Abasto ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"feather icon-trending-down mr-2"}),_vm._v("Hospital con Menor Abasto ")])
}]

export { render, staticRenderFns }