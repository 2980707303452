import { render, staticRenderFns } from "./ChartTableComponentPrecription.vue?vue&type=template&id=45639dbf&scoped=true"
import script from "./ChartTableComponentPrecription.vue?vue&type=script&lang=js"
export * from "./ChartTableComponentPrecription.vue?vue&type=script&lang=js"
import style0 from "@/other/assets/css/app.css?vue&type=style&index=0&id=45639dbf&prod&scoped=true&lang=css&external"
import style1 from "./ChartTableComponentPrecription.vue?vue&type=style&index=1&id=45639dbf&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45639dbf",
  null
  
)

export default component.exports