import colotla from "@/other/assets/json/jalisco/01_colotlan.json";
import lagos_de_moreno from "@/other/assets/json/jalisco/02_lagos_de_moreno.json";
import tepatitlan from "@/other/assets/json/jalisco/03_tepatitlan.json";
import la_barca from "@/other/assets/json/jalisco/04_la_barca.json";
import tamazula from "@/other/assets/json/jalisco/05_tamazula.json";
import ciudad_guzman from "@/other/assets/json/jalisco/06_ciudad_guzman.json";
import autlan_de_navarro from "@/other/assets/json/jalisco/07_autlan_de_navarro.json";
import puerto_vallarta from "@/other/assets/json/jalisco/08_puerto_vallarta.json";
import ameca from "@/other/assets/json/jalisco/09_ameca.json";
import zapopan from "@/other/assets/json/jalisco/10_zapopan.json";
import tonala from "@/other/assets/json/jalisco/11_tonala.json";
import tlaquepaque from "@/other/assets/json/jalisco/12_tlaquepaque.json";
import guadalajara from "@/other/assets/json/jalisco/13_guadalajara.json";

export default class UtilFront {
  /* JALISCO UTILS */
  /**
   * @method
   * @param {number} val Recibe el valor numerico id de la jurisdicción
   * @returns {Object} El svg del mapa que va utilizar para mostrar
   * en tablero de control
   **/
  static getMapStateJalisco(val) {
    let map = [
      colotla,
      lagos_de_moreno,
      tepatitlan,
      la_barca,
      tamazula,
      ciudad_guzman,
      autlan_de_navarro,
      puerto_vallarta,
      ameca,
      zapopan,
      tonala,
      tlaquepaque,
      guadalajara,
    ];
    if (parseInt(val) < 1 || parseInt(val) > 14)
      throw new Error("Out of range");
    return map[parseInt(val) - 2];
  }
  /**
   * @method
   * @param {number} val Recibe el valor numerico id de la jurisdicción
   * @returns {String} El color depende del valor del id
   **/
  static colorMap(val) {
    let color = [
      "#ffffff",
      "#8ecae6",
      "#95da89",
      "#e59cc1",
      "#72b4af",
      "#f06e9a",
      "#8dbd97",
      "#8d74c9",
      "#70c9e2",
      "#f2d670",
      "#bcdeea",
      "#f89d7c",
      "#adb5bd",
      "#FF7171",
      "#ffc0cd",
    ];
    if (parseInt(val) < 0 || parseInt(val) > 14) return "transparent";
    return color[val];
  }

  /**
   * @method
   * @param {number} val Recibe el valor numerico
   * @returns {String} El color depende del valor del porcentaje
   **/
  static colorPercentaje(val) {
    return parseInt(val) > 85
      ? ["#37BC7F"]
      : parseInt(val) >= 70 && parseInt(val) <= 85
      ? ["#ffc300"]
      : parseInt(val) >= 55 && parseInt(val) <= 70
      ? ["#ff9500"]
      : parseInt(val) >= 1 && parseInt(val) <= 54
      ? ["#da1e37"]
      : ["#adb5bd"];
  }
  /**
   * Función global para la barra de porcentaje dentro de las tablas
   * @method
   * @param {number} percentaje Recibe el valor numerico
   * @returns {String} El color depende del valor del porcentaje
   **/
  static colorPercentajeTable(percentaje) {
    return percentaje > 85
      ? "#37BC7F"
      : percentaje >= 70 && percentaje <= 85
      ? "#ffc300"
      : percentaje >= 55 && percentaje <= 70
      ? "#ff9500"
      : percentaje > 0 && percentaje <= 54
      ? "#da1e37"
      : "#fff";
  }

  static colorPercentajeBar(percentaje) {
    return percentaje > 85
      ? "#37BC7F"
      : percentaje >= 70 && percentaje <= 85
      ? "#ffc300"
      : percentaje >= 55 && percentaje <= 70
      ? "#ff9500"
      : percentaje > 0 && percentaje <= 54
      ? "#da1e37"
      : "#adb5bd";
  }

  /**
   * @method
   * @param {number} val Recibe el valor numerico id de la jurisdicción
   * @returns {Object} El svg del mapa que va utilizar para mostrar
   * en tablero de control
   **/
  static getMapStateJaliscoNum(val) {
    let map = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
    ];
    if (parseInt(val) < 1 || parseInt(val) > 16)
      throw new Error("Out of range");
    return map[parseInt(val) - 2];
  }

  /**
   * It takes a string and returns a number.
   * @param val - The value of the state in roman numerals
   * @returns The return value is the value of the case that matches the value of the parameter.
   */
  static getMapStateJaliscoNumRomanos(val) {
    switch (val) {
      case "I":
        return 2;
      case "II":
        return 3;
      case "III":
        return 4;
      case "IV":
        return 5;
      case "V":
        return 6;
      case "VI":
        return 7;
      case "VII":
        return 8;
      case "VIII":
        return 9;
      case "IX":
        return 10;
      case "X":
        return 11;
      case "XI":
        return 12;
      case "XII":
        return 13;
      case "XIII":
        return 14;
    }
  }

  /**
   * It takes a number between 1 and 16 and returns a color
   * @param val - The value of the bar.
   * @returns The color of the bar.
   */
  static colorBar(val) {
    let color = [
      "rgba(149,218,137,255)",
      "rgba(229,156,193,255)",
      "rgba(114,180,175,255)",
      "rgba(240,110,154,255)",
      "rgba(141,189,151,255)",
      "rgba(141,116,201,255)",
      "rgba(112,201,226,255)",
      "rgba(242,214,115,255)",
      "rgba(188,222,234,255)",
      "rgba(248,157,124,255)",
      "rgba(173,181,189,255)",
      "rgba(255,113,113,255)",
      "#ffc0cd",
    ];
    if (parseInt(val) < 1 || parseInt(val) > 16)
      throw new Error("Out of range");
    return color[parseInt(val) - 2];
  }

  /**
   * This function returns an array of strings, each string is a hex color code.
   * @returns An array of strings.
   */
  static getpointBorderColorGreen() {
    return [
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
      "#37BC7F",
    ];
  }

  /**
   * This function returns an array of strings, each string is a hex color code.
   * @returns An array of strings.
   */
  static getpointBorderColorBlue() {
    return [
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
      "#00a0ff",
    ];
  }

  /**
   * This function returns an array of strings, each string is a hex color code.
   * @returns An array of strings.
   */
  static getpointBorderColorOrange() {
    return [
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
      "#f4730b",
    ];
  }

   /**
   * This function returns an array of strings, each string is a hex color code.
   * @returns An array of strings.
   */
   static getpointBorderColorGreenNew() {
    return [
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
      "#EEC944",
    ];
  }
/**
 * This function returns an array of strings that are the background colors of the points on the chart.
 * @returns An array of strings.
 */

  static getpointBackgroundColor() {
    return [
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
    ];
  }
}
