var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('BreadCrumBarComponent')],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('CardComponents')],1)]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-4"},[_c('ChartBarPercentageComponent',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.generalAverage.percentageSupply != 0 &&
            _vm.generalAverage.percentageKey != 0
        ),expression:"\n          generalAverage.percentageSupply != 0 &&\n            generalAverage.percentageKey != 0\n        "}]})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.generalAverage.percentageSupply != 0),expression:"generalAverage.percentageSupply != 0"}],staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-4"},[_c('JaliscoDoughutCharComponent',{ref:"percentaje_supply",attrs:{"id":"percentaje_supply","percentage":_vm.generalAverage.percentageSupply,"title":"PORCENTAJE DE ABASTO EN EL CENTRO DE SALUD","generalAverage":_vm.generalAverage}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.generalAverage.percentageKey != 0),expression:"generalAverage.percentageKey != 0"}],staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-4"},[_c('JaliscoDoughutCharComponent',{ref:"percentaje_key",attrs:{"id":"percentaje_key","percentage":_vm.generalAverage.percentageKey,"title":"NIVEL DE EXISTENCIA ÓPTIMO","generalAverage":_vm.generalAverage}})],1)]),(
      _vm.generalAverage.percentageSupply == 0 &&
        _vm.generalAverage.percentageKey == 0
    )?_c('div',{staticClass:"breadcrumbbar m-0 text-center"},[_c('h2',{attrs:{"id":"noinfo"}},[_vm._v(" SUCURSAL SIN PARAMETROS ")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.warehauseDetailStockDistributionBoard.length != 0),expression:"warehauseDetailStockDistributionBoard.length != 0"}],staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('TableComponent',{attrs:{"headers":_vm.headers,"dataList":_vm.warehauseDetailStockDistributionBoard,"onChangeTable":_vm.onChangeTable,"title":"Claves Dentro del Cuadro de Distribución","isDetail":true}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.warehauseDetail.length != 0),expression:"warehauseDetail.length != 0"}],staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('TableComponent',{attrs:{"headers":_vm.headers_another,"dataList":_vm.warehauseDetail,"onChangeTable":_vm.onChangeTable,"isDetail":false,"title":"Claves Fuera del Cuadro de Distribución"}})],1)]),_c('DialogComponent',{attrs:{"dialog":_vm.dialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }