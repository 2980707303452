var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('div',{staticClass:"card text-center m-b-20"},[_c('div',{staticClass:"card-body pt-3 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 text-center pb-0"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.complement()[1]))]),_c('h1',[_c('span',{staticClass:"badge",style:({
                  borderRadius: '10px',
                  color: '#fff',
                  backgroundColor:
                    _vm.id == 'general'
                      ? _vm.utilFront.colorPercentaje(_vm.percentaje)
                      : _vm.utilFront.colorPercentaje(_vm.percentaje.percentage),
                })},[_vm._v(_vm._s(_vm.id == "general" ? _vm.percentaje + " %" : _vm.percentaje.percentage + " %"))])])])]),_c('div',{staticClass:"col-sm-12 text-center"},[_c('h6',{staticClass:"pb-0 pt-4"},[_c('i',{class:_vm.complement()[0]}),_vm._v(" "+_vm._s(_vm.complement()[2])+" ")])]),_c('div',{staticClass:"progress",staticStyle:{"height":"7px"}},[_c('div',{staticClass:"progress-bar",style:({
              width:
                _vm.id == 'general'
                  ? _vm.percentaje + '%'
                  : _vm.percentaje.percentage + '%',
              backgroundColor:
                _vm.id == 'general'
                  ? _vm.utilFront.colorPercentaje(_vm.percentaje)
                  : _vm.utilFront.colorPercentaje(_vm.percentaje.percentage),
            }),attrs:{"role":"progressbar","aria-valuemin":"0","aria-valuemax":"100"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }